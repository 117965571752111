.videoBgContainer
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    z-index: -9
    width: 100%
    height: 100%
    overflow: hidden
    .videoBg
        display: flex
        justify-content: center
        align-items: center
        min-width: 100%
        min-height: 100%
        height: 100%
        object-fit: cover
        min-width: 100%
        min-height: 100%
        height: 100%
.logo
    height: 20vh
    width: 20vh
    display: flex
    justify-content: center
    align-items: center
    cursor: default
    user-select: none
    .logoLineContainer
        position: absolute
        .logoLine
            height: 50%
            margin: -40px
            font-size: 100px
            color: white
            // text-transform: lowercase
            font-family: "Volta"
            .outlinedletter
                pointer-events: none !important
            .borderLetter
                text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000
            @media screen and ( max-width: 750px )
                line-height: 105px
                font-size: 60px
            @media screen and ( min-width: 1100px )
                font-size: 135px

.bannerContainer
    z-index: -1
    height: 100vh
    width: 100vw
    overflow: hidden
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    .banner
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        transform: rotate(-5deg)
        width: 110vw
        min-height: 30px
        height: 4.5vw
        max-height: 60px
        color: white
        background-color: black
        margin: 0 auto
        white-space: nowrap
        overflow: hidden
        h5
            font-size: 1.7vw
            letter-spacing: 5px
            display: inline-block
            padding-left: 100%
            animation: scrollingtext 25s linear infinite
            @media screen and ( max-width: 750px )
                font-size: 15px

.softBannerContainer
    width: 100vw
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 82vh
    @media screen and ( max-width: 750px )
        top: 72vh
    .banner
        display: flex
        justify-content: center
        align-items: center
        position: absolute
        width: 100vw
        min-height: 40px
        height: 6vw
        max-height: 60px
        color: white
        margin: 0 auto
        white-space: nowrap
        overflow: hidden
        span
            font-family: "Univers"
            margin: 3px
            text-transform: uppercase
            @media screen and ( max-width: 750px )
                margin: 2px
                font-size: 12px

.scrollIconContainer
    position: absolute
    top: 82vh
    cursor: pointer
    @media screen and ( max-width: 750px )
        top: 72vh
    > div > img
        width: 130px
        @media screen and ( max-width: 750px )
            width: 100px
