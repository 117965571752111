.contact_container
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    width: 100vw
    min-height: 100vh
    overflow: hidden
    .contact_logo
        display: flex
        justify-content: center
        align-items: center
        height: 550px
        // min-height: var(--vhmobileheight
        width: 100vw
        overflow: hidden
    .contactTextContainer
        display: flex
        justify-content: center
        align-items: center
        width: 100vw
        min-height: 40vh
        margin: 5% 0 5% 0
        font-family: "Univers"
        line-height: 28px
        font-size: 16px
        @media screen and ( max-width: 750px )
            font-size: 14px !important
            line-height: 20px !important
        .contactTextInner
            min-width: 300px
            width: 30%
            max-width: 700px
            h1
                a
                    border: 1px solid black
                    border-radius: 15px
                    padding: 11px 8px 5px 8px
                    color: white !important
                    text-decoration: none
                    background-color: black
                    &:visited
                        color: white !important
            a
                color: black !important
                &:visited
                    color: black !important
            p
                margin: 25px 0 25px 0
    .footer_container
        width: 100vw
        height: 200px
