.buttonContainer
    display: flex
    justify-content: center
    align-items: center
    min-width: 110px
    width: 10vw
    max-width: 150px
    height: 30px
    cursor: pointer
    user-select: none
    // border: 1px solid black
    // border-radius: 15px
    // background-color: white
    @media screen and ( max-width: 320px )
        min-width: 90px
    .buttonIcon
        display: flex
        justify-content: center
        align-items: center
        > svg
            color: black
    .buttonText
        font-size: 15px
        color: black
        user-select: none
        @media screen and ( max-width: 750px )
            font-size: 14px !important
