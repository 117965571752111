.notFoundContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100vw
    height: 100vh
    overflow: hidden
    .notFoundContent
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 100%
        height: 25%
        .notFoundText
            font-family: "Univers"
            color: black
            font-size: 24px
            &.subText
                font-size: 20px

//DESKTOP
// @media screen and ( min-width: 940px )

//MOBILE
// @media screen and ( max-width: 940px )
