.swiper-container
    height: 35vw
    min-height: 150px
    width: 100%
    .swiper-wrapper
        height: 100%
        .swiper-slide
            object-fit: cover
            // width: 100%
            height: 100%
            max-width: 100%
            max-height: 100%
            // width: 200px !important
            // margin-right: 2.5% !important
            .videoPageStill
                max-width: 100%
                height: calc(100% - 5px)
                max-height: 100%
                object-fit: cover
                // max-width: 100%
                // max-height: 100%
