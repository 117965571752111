.home_container
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100vw
    overflow: hidden
    .home_logo
        display: flex
        justify-content: center
        align-items: center
        min-height: 100vh
        // min-height: var(--vhmobileheight
        width: 100vw
        overflow: hidden
        @media screen and ( max-width: 700px )
            min-height: 86vh
    .home_filters_container
        display: flex
        justify-content: center
        align-items: center
        min-height: 80px
        height: 9vw
        max-height: 150px
        min-width: 380px
        width: 60vw
        max-width: 700px
        background-color: white
        padding-left: 20%
        padding-right: 20%
        @media screen and ( max-width: 320px )
            min-width: 315px
        .home_filters_inner
            display: flex
            justify-content: space-evenly
            align-items: center
            width: 100%
    .home_videos_container
        display: flex
        justify-content: center
        align-items: center
        min-height: 40vh
        min-width: 310px
        width: 95vw
        max-width: 1100px
        margin-bottom: 40px
    .footer_container
        width: 100vw
        height: 200px

@keyframes scrollingtext
    0%
        transform: translate(20%, 0)
    100%
        transform: translate(-20%, 0)
