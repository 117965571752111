.home_videos_inner
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: center
    width: 100%
    background-color: black
    .videoLineContainer
        display: flex
        justify-content: space-evenly
        align-items: center
        width: 100%
        cursor: pointer
        height: 15vw
        height: clamp(80px, 15vw, 150px)
        // min-height: 80px
        // height: 15vw
        // max-height: 180px
        transition: padding-top 0.15s ease-in-out, padding-bottom 0.15s ease-in-out
        padding-top: 0px
        padding-bottom: 0px
        &:hover
            padding-top: 15px
            padding-bottom: 15px
        &:hover .videoBlockContentText
            opacity: 1 !important
        &:hover .videoBlockContentImage
            filter: brightness(0.5)
        .videoLineinner
            display: flex
            justify-content: space-evenly
            align-items: center
            margin: 1% 0% 1% 0%
            width: 100%
            height: 98%
            .videoBlockContainer
                display: flex
                justify-content: center
                align-items: center
                // flex-wrap: wrap
                width: 33%
                height: 100%
                .videoBlockContentImage
                    transition: filter 0.3s
                    object-fit: cover
                    min-width: 100%
                    height: 100%
                    max-height: 100%
                .videoBlockContentText
                    width: 28%
                    font-size: 22px
                    transition: opacity 0.3s
                    opacity: 0
                    position: absolute
                    color: white
                    user-select: none
                    @media screen and ( max-width: 750px )
                        font-size: 12px
    .resetFiltersContainer
        width: 100%
        background-color: white
        .resetFilters
            transition: transform 0.3s
            cursor: pointer
            &:hover
                transform: scale(1.05)
