.navContainer
    display: flex
    width: 100vw
    justify-content: center
    align-items: center
    position: fixed
    // top: 65vh
    // top: calc(var(--vhmobileheight) *0.01 * 70)
    top: 0px
    left: 15px
    z-index: 999
    pointer-events: none
    .navInner
        display: flex
        flex-direction: row
        justify-content: flex-start
        width: 100%
        max-width: 1650px
        pointer-events: none
        .navButtonContainer
            display: flex
            justify-content: center
            align-items: center
            height: 50px
            width: 50px
            margin: 15px 15px 15px 0px
            border-radius: 15px 0px
            border: solid 1px black
            background-color: white
            cursor: pointer
            user-select: none
            pointer-events: all
            @media screen and ( max-width: 750px )
                height: 42px
                width: 42px
            &:hover img
                transform: scale(1.1)
            > img
                transition: transform 0.3s
                height: 35px
                width: 35px
                @media screen and ( max-width: 750px )
                    height: 30px
                    width: 30px
