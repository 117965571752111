.footer_inner
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    background-color: black
    -webkit-border-top-left-radius: 15px
    -moz-border-radius-topleft: 15px
    border-top-left-radius: 15px
    color: white
    .block
        display: flex
        justify-content: center
        align-items: center
        height: 100%
        width: 30%
        max-width: 350px
        > img
            min-width: 150px
            width: 80%
            max-width: 200px
        .mirror
            webkit-transform: scaleX(-1)
            transform: scaleX(-1)
        &.middle
            display: flex
            flex-direction: column
            justify-content: space-evenly
            align-items: center
            user-select: none
            .title
                width: 60vw
                margin: 0px
                cursor: pointer
                font-size: 2.5vw
                // text-transform: lowercase
                font-family: "Volta"
                // text-shadow: 1.5px 1.5px var(--accentColor)
                @media screen and ( max-width: 750px )
                    font-size: 18px !important
            .iconsContainer
                display: flex
                justify-content: space-evenly
                align-items: center
                width: 80%
                > a
                    color: white
                    transition: transform 0.2s
                    cursor: pointer
                    &:hover
                        transform: scale(1.2)
            .credits
                font-size: 12px
                font-family: "Univers"
                width: 80vw
