.videoPageContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100vw
    overflow: hidden
    .videoPageHeaderContainer
        background-color: black
        display: flex
        justify-content: center
        align-items: flex-start
        // height: 80vh
        // max-height: 600px
        width: 100vw
        overflow: hidden
        height: 80vw
        max-height: 700px
    .videoPageTitleContainer
        display: flex
        justify-content: center
        align-items: center
        height: 80px
        width: 85%
        font-size: 30px
        font-weight: 300
        letter-spacing: 1vw
        @media screen and ( max-width: 750px )
            font-size: 18px
    // .videoPageSliderContainer
    .videoPageCreditsContainer
        display: flex
        justify-content: center
        align-items: center
        text-align: left
        width: 100vw
        min-height: 40vh
        margin: 5% 0 5% 0
        font-family: "Univers"
        font-size: 16px
        line-height: 30px
        @media screen and ( max-width: 750px )
            font-size: 13px
            line-height: 20px
        .videoPageCreditsInner
            min-width: 300px
            width: 30%
            max-width: 700px
    .footer_container
        width: 100vw
        height: 200px
